import React from "react";
import { TextField, Switch, Box, FormControlLabel, FormLabel, RadioGroup, Radio, FormControl } from "@mui/material";
import { useField } from "formik";
import imgInfo from "../../../assets/icons/info.svg"

export function FormikTextInput({
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    labelText,
    name,
    placeholder,
    defaultValue,
    type,
    ...props
}) {
    const [field, meta] = useField(name);

    return (
        <>
            <TextField
                className={className}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                id={id}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                InputLabelProps={{
                    ...InputLabelProps,
                    style: { fontSize: "1.3em" },
                    shrink: true,
                }}
                InputProps={{
                    ...InputProps,
                    autoComplete: "false",
                }}
                label={labelText}
                margin="dense"
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                type={type}
                variant="standard"
                {...field}
                {...props}
            />
        </>
    );
}

export function FormikNumberInput({
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    labelText,
    name,
    placeholder,
    defaultValue,
    type,
    ...props
}) {
    const [field, meta] = useField(name);

    return (
        <>
            <TextField
                className={className}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                id={id}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                InputLabelProps={{
                    ...InputLabelProps,
                    style: { fontSize: "1.3em" },
                    shrink: true,
                }}
                InputProps={{
                    ...InputProps,
                    autoComplete: "false",
                }}
                label={labelText}
                margin="dense"
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                type={"number"}
                variant="standard"
                {...field}
                {...props}
            />
        </>
    );
}

export function FormikSelectInput({
    children,
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    labelText,
    name,
    defaultValue,
    ...props
}) {
    const [field, meta] = useField(name);
    return (
        <>
            <TextField
                className={className}
                defaultValue={defaultValue}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                id={id}
                InputLabelProps={{
                    style: { fontSize: "1.3rem" },
                    shrink: true,
                }}
                label={labelText}
                margin="dense"
                name={name}
                select
                SelectProps={{ native: true }}
                variant="standard"
                {...field}
                {...props}
            >
                {children}
            </TextField>
        </>
    );
}

export const FormikYesOrNoInput = ({ labelText, name }) => {
    const [field, meta] = useField(name);

    return (
        <Box display={"flex"}>
            <p style={{ margin: "5px" }}>No</p>
            <Switch name="requiereFirma"{...field}
                checked={field.value === true}
                value={field.value}
            />
            <p style={{ margin: "5px" }}>Si</p>
        </Box>
    );
}

// export function FormikDateInput({
//     className,
//     disabled,
//     fullWidth = true,
//     helperText,
//     id,
//     InputLabelProps,
//     InputProps,
//     labelText,
//     name,
//     placeholder,
//     defaultValue,
//     type,
//     ...props
// }) {
//     const [field, meta] = useField(name);

//     return (
//         <>
//             <TextField
//                 type="date"
//                 className={className}
//                 disabled={disabled}
//                 error={meta.touched && meta.error && meta.error}
//                 fullWidth={fullWidth}
//                 id={id}
//                 helperText={
//                     meta.error && meta.touched ? meta.error : helperText
//                 }
//                 InputLabelProps={{
//                     ...InputLabelProps,
//                     style: { fontSize: "1.3em" },
//                     shrink: true,
//                 }}
//                 InputProps={{
//                     ...InputProps,
//                     autoComplete: "false",
//                 }}
//                 label={labelText}
//                 margin="dense"
//                 name={name}
//                 placeholder={placeholder}
//                 defaultValue={defaultValue}
//                 variant="standard"
//                 {...field}
//                 {...props}
//             />
//         </>
//     );
// }
export function FormikRadioGroup({ name, formik, label, info, right, className,disabled }) {
    
    return (
        <>
        <FormControl disabled={disabled}>
            <div style={{display: "flex", alignItems:"center", gap: "10px"}}>
                <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
                {info && <div className={`container-info`}>
                    <img src={imgInfo} alt="i" style={{display: "flex"}}/>
                    <section className={`data ${right && "right"}`}>{info}</section>
                    </div>}
            </div>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={name}
                value={formik.values[name]}
                onChange={e => {
                    formik.setFieldTouched(name);
                    formik.handleChange(e);
                }}
                onBlur={e => {
                    formik.setFieldTouched(name);
                    formik.handleBlur(e);
                }}
            >
                <FormControlLabel control={<Radio />} label="Si" value={true} />
                <FormControlLabel control={<Radio />} label="No" value={false} />
            </RadioGroup>
        </FormControl>
        {
            // formik?.touched[name] && formik?.errors[name]  &&  <Typography variant='subtitle1' color={"error"} alignSelf={"start"} fontSize={"12px"} >{formik.errors[name]}</Typography>
       }
       </>
    )
};
export function FormikDateInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="date"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        />
    )
};