import { makeStyles } from "@mui/styles";

export const HeaderStyles = makeStyles(() => ({
    root: {
        height: '100px',
        display: 'flex',
        backgroundColor: '#006E54 !important',
        boxShadow: 'none',
    },
    toolbar: {
        marginLeft: '1rem',
        padding: '1.5rem',
       
    },
    wordKey: {
        color: '#fff',
        fontSize: '1.5rem !important',
        fontWeight: 300,
        padding: '2rem'
    },
    menuIcon: {
        color: '#fff',
    },
    drawer: {
        width: '270px',
        boxShadow: '0px 3px 6px #00000029',
        '& .MuiPaper-root': {
            width: '270px',
        }
    },
    userDescription: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '150px',
        background: '#f2f2f2 0% 0% no-repeat padding-box'
    },
    avatar: {
        height: '6rem',
        width: '6rem',
        borderRadius: 50
    },
    usernameContainer: {
        lineHeight: '1rem',
        paddingTop: '0.6rem',
        marginTop: '31px',
        '& p': {
            color: '#a7a7a7',
            fontSize: '1rem',
            fontWeight: 400,
            margin: '1rem 0',
            padding: 0
        }
    },
    logout: {
        display: 'flex',
        cursor: 'pointer',
        '& p': {
            fontWeight: 500,
        },
        '& img': {
            marginRight: '0.6rem',
            width: '1.3rem'
        }
    },
    listContainer: {
        backgroundColor: '#006E54',
        flexGrow: 1,
        paddingLeft: '3%'
    },
    drawerFooter: {
        background: '#f2f2f2 0% 0% no-repeat padding-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '4vh',
        color: '#707070',
        fontSize: '0.8rem',
        padding: '0.6rem',
        fontWeight: 400
    },
    drawerHandler: {
        height: "100%",
        width: "100vw",
        backgroundColor: "transparent",
        position: "absolute",
        zIndex: 1150,
        right: 0,
        top: 0
    }
}));
