import React, { useCallback } from 'react'
import styles from "../StylesGeneral.module.scss"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import XGridServer from '../../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantSucursalCajaGet} from "../../../services/hooli-services/Merchants/MerchantSucursalCajaGet"
import HeaderButton from '../../../components/Button/HeaderButton';
import Modal from '../../../components/Modal/Modal';
import EditCajas from './Actions/EditCajas';
import AddCajas from './Actions/AddCajas';
import { ACTIONS } from '../../../consts/Actions';
import { Add, Cached, EditOutlined } from '@mui/icons-material';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import DelCajas from './Actions/DelCajas';
import QrCajas from './Actions/QrCajas';

export default function Cajas() {
    const [selectRow, setSelectRow] = useState({ status: false, row: {} })
    const [cajasList, setCajasList] = useState(false);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [forceUpload, setForceUpload] = useState(false);
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const columns = [
        { field: 'merchantCajaNombre', headerName: 'Nombre', flex: 1, minWidth: 100 },
        { field: 'merchantCajaNumero', headerName: 'Número', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
        { field: 'merchantSucursalNombre', headerName: 'Sucursal', flex: 1, minWidth: 100 },
    ];
    
    const getSucursalesCajas = async (cred) => {
        const data = await MerchantSucursalCajaGet(cred, cred.merchantId)
        if(data?.status?.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantCajaId,
                merchantCajaEsStore: data.merchantCajaEsStore,
                merchantCajaId: data.merchantCajaId,
                merchantCajaNombre: data.merchantCajaNombre,
                merchantCajaNumero: data.merchantCajaNumero,
                merchantCajaUFH: data.merchantCajaUFH,
                merchantCajaUID: data.merchantCajaUID,
                merchantCajaVersion: data.merchantCajaVersion,
                merchantId: data.merchantId,
                merchantNombre: data.merchantNombre,
                merchantSucursalEsCasaCentral: data.merchantSucursalEsCasaCentral,
                merchantSucursalId: data.merchantSucursalId,
                merchantSucursalNombre: data.merchantSucursalNombre,
            }))
            setCajasList(formatList)
        }
    }
      const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const updateList = useCallback(() => {
        setSelectRow({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])
    const handleClick = ({ procede, title }) => {
        console.log("procede", procede)
        setModalDetails({ procede, title, isOpen: true });
    };
    useEffect(() => {
        if (credentials.merchantId){
            getSucursalesCajas(credentials)
        }
    }, [credentials])
    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getSucursalesCajas(credentials)
        }
    }, [openModal])
  return (
    <>
    <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} /* close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} */ >
            {modalDetails.procede === ACTIONS.INSERT && (
                <AddCajas
                    user={selectRow.row}
                    // servicio={service}
                    updateList={() => getSucursalesCajas(credentials)}
                    closeModal={closeModal}
                />
            )}
            {modalDetails.procede === ACTIONS.UPDATE && (
                <EditCajas
                    user={selectRow.row}
                    // roles={connectionRoles}
                    updateList={() => getSucursalesCajas(credentials)}
                    closeModal={closeModal}
                />
            )}
            {modalDetails.procede === ACTIONS.DELETE && (
                <DelCajas
                    user={selectRow.row}
                    // servicio={service}
                    updateList={() => getSucursalesCajas(credentials)}
                    closeModal={closeModal}
                />
            )}
            {modalDetails.procede === ACTIONS.EXPORT && (
                <QrCajas
                    user={selectRow.row}
                    // servicio={service}
                    updateList={updateList}
                    closeModal={closeModal}
                />
            )}
        </Modal>
    <div className={styles.container}>
        <div className={styles.containerFilters}>
            <div className={styles.containerButtons}>
                <HeaderButton
                    text={"Actualizar"}
                    onClick={() => { getSucursalesCajas(credentials) }}
                    disabled={selectRow.row.id || false}
                >
                    <Cached />
                </HeaderButton>
                <HeaderButton
                    text={"Nueva caja"}
                    handleClick={handleClick}
                    data={{
                        title: "Nueva caja",
                        procede: ACTIONS.INSERT
                    }}
                    disabled={selectRow.row.id}
                >
                    <Add />
                </HeaderButton>
                <HeaderButton
                    text={"Modificar caja"}
                    handleClick={handleClick}
                    data={{
                        title: "Modificar caja",
                        procede: ACTIONS.UPDATE
                    }}
                    disabled={!selectRow.row.id || selectRow.row.merchantCajaEsStore === "Y"}
                >
                    <EditOutlined />
                </HeaderButton>
                <HeaderButton
                    text={"Eliminar caja"}
                    handleClick={handleClick}
                    data={{
                        title: "Eliminar caja",
                        procede: ACTIONS.DELETE
                    }}
                    disabled={!selectRow.row.id || selectRow.row.merchantCajaEsStore === "Y"}
                >
                    <DeleteOutlineRoundedIcon />
                </HeaderButton>
                <HeaderButton
                    text={"Generar QR"}
                    handleClick={handleClick}
                    data={{
                        title: "Generar QR",
                        procede: ACTIONS.EXPORT
                    }}
                    disabled={!selectRow.row.id}
                >
                    <QrCode2RoundedIcon/>
                </HeaderButton>
            </div>
        </div>
        <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={cajasList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
    </div>
</>
    )
}
