import { Box, Checkbox, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InsertIcon from "../../../assets/icons/insert-icon.png";
import styles from "./index.module.scss";
import IconFile from "../../../assets/icons/icon-file.png"
import check from "../../../assets/icons/check.svg"
export function DropZone({file, setFile, horizontal=false, update, optional=false, checked=false, allFiles = false, modal, setChecked=()=>{} }) {
    const [errorFile,setErrorFile] = useState(false);
    
    const handleChange = (e) => {
        if(file?.length === 0 || !file){
            setChecked(!checked)
        }
    }
    const onDrop = async (acceptedFiles, rejectedFiles) => {
        setChecked(false)
        if (acceptedFiles[0].path === ".pdf") {
            return setErrorFile('El archivo debe tener un nombre')
        }
        if (rejectedFiles.length > 0) {
            if (rejectedFiles[0].errors[0].code === "file-too-large") {
                return setErrorFile('Solo se permiten archivos menores a 50 MB')
            }
            else {
                return setErrorFile('Solo se permiten archivos PDF.');
            }
        }
        if(horizontal){
            for (let i = 0; i < file.length; i++) {
                if(file[i].name === acceptedFiles[0].name){
                    console.log("Repetido")
                    return setErrorFile('El archivo ya se encuentra agregado')
                } 
            }
            setFile([...file, acceptedFiles[0]]);
        } else {
            setFile(acceptedFiles);
        }
       
    }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: !allFiles ? {
            "application/pdf": [".pdf"]
        } : {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
        },
        maxSize:50 * 1024 * 1024,
        onDrop
    });


    const renderDropZone = ()=> (
        <Box width={"100%"} marginBottom={4}> 
            <div {...getRootProps({ className: 'dropzone' })} className={styles.dropZone} > 
                 <input {...getInputProps()} />
                 <img src={InsertIcon} alt="icon-upload"/>
                 <p> <span style={{color:"#006E54", fontWeight:600,cursor:"pointer"}}>Subí el documento </span>  <span style={{ color:"#8A8A8A", fontWeight:600 }}> o arrastralo aquí </span></p>
                 <p style={{color:"#8A8A8A", fontSize:"14px", marginTop:"8px"} }>Hasta 50 MB en .pdf</p>
            </div>
        </Box>
    )
    const renderFileSection = ()=> (
        <Box className={styles.containerFile}  marginBottom={4} > 
            <Box display={"flex"}  alignItems={"center"}> 
                <img  src={IconFile} alt='icon-file'/>
                <h1>{file[0]?.path}</h1>
            </Box>
            <Box>
                <div className={styles.btnDelete} onClick={()=> setFile(null)}>
                    Eliminar
                </div>
            </Box>
        </Box>
    )
    const renderHorizontal = ()=> (
        <div style={{position:"relative"}}>
        <Box className={modal ? styles.containerFileHorizontalModal : styles.containerFileHorizontal} width={"800px"} display={"flex"} gap={4}> 
        <Box width={"50%"} marginBottom={2}> 
            <div {...getRootProps({ className: 'dropzone' })} className={modal ? styles.dropZoneHorizontalModal : styles.dropZoneHorizontal} > 
                 <input {...getInputProps()} />
                 <img src={InsertIcon} alt="icon-upload"/>
                 <p> <span style={{color:"#006E54", fontWeight:600,cursor:"pointer"}}>Subí la constancia </span>  <span style={{ color:"#8A8A8A", fontWeight:600 }}> o arrastralo aquí </span></p>
                 <p style={{color:"#8A8A8A", fontSize:"14px", marginTop:"8px"} }>{allFiles ? "Hasta 50 MB en .pdf, .jpg, .png" :"Hasta 50 MB en .pdf"}</p>
            </div>
            
        </Box>
        <Box width={"50%"} minHeight={modal ? "180px" : "280px"} maxHeight={modal ? "180px" : "280px"} marginTop={modal ? "20px" : 0} overflow={"auto"} display={"flex"} flexDirection={"column"} gap={1} marginBottom={2}> 
            {file.length > 0 &&
            file?.map((archive, i) => (
            <Box className={styles.containerUploadHorizontal} position={"relative"} padding={"8px"} key={i}> 
                <Box display={"flex"} alignItems={"center"}>
                    <img  src={IconFile} alt='icon-file'/>
                    <h1 style={{fontWeight: "400"}}>{archive?.path}</h1>
                </Box>
                {update?.includes(i) ? <img src={check} alt='Check'/>
                     : <div className={styles.btnDelete} onClick={()=> setFile(file.filter((file, index)=> index !== i))}>
                    Eliminar
                </div>}
                </Box>
                ))
            }
            <Box>
            </Box>
        </Box>
        </Box>
        <p style={modal ? {position: "absolute", right: "-22%", bottom:"30px", color: "#8A8A8A"} : {position: "absolute", right: "37%", bottom:"16px", color: "#8A8A8A"}}>{file.length} archivos</p>
        </div>
    )
  return (
    <Grid padding={"0px 0 12px 0"}>
        {horizontal ? 
            renderHorizontal() :
            file && !errorFile? 
            renderFileSection()  
            :
            renderDropZone()
        }
        {optional && <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                color="success" 
            />
            <p style={{marginLeft:"10px", color:"#8A8A8A", fontSize:"18px"}}>{optional}</p>
        </Box>}
    </Grid>
  )
}

