import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const LocalidadGet = async (arg: UserCredentialsType, provinciaId: string) => {
    const body: AxiosPostBody = {
        "service": "LocalidadGet",
        "params": {
            provinciaId
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
}