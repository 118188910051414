import axiosPost from '../axiosPost';
import { Caja, CajaEdit, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaUpd = async (arg: UserCredentialsType, data: CajaEdit) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaUpd",
        "params": {
            merchantCajaId : data.merchantCajaId,
            merchantCajaNombre: data.merchantCajaNombre,
            merchantCajaNumero: data.merchantCajaNumero,
            merchantCajaVersion: data.merchantCajaVersion,
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        if(result?.status?.code === 0) {
            return [result.status]
        } else {
            return result.result;
        }
}