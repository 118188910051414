import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import Map from '../../../../components/map/index.jsx'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantSucursalUpd } from '../../../../services/hooli-services/Merchants/MerchantSucursalUpd.ts'
import { MerchantSucursalGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalGet.ts'
import { ProvinciaGet } from '../../../../services/hooli-services/Provincias/ProvinciasGet.ts'
import { LocalidadGet } from '../../../../services/hooli-services/Merchants/LocalidadGet.ts'

export default function EditSucursal({ updateList, servicio, closeModal, row }) {
    const [localidades, setLocalidades] = useState([{localidadId: 1, localidadNombre: "Ciudad Autónoma de Buenos Aires"}]);
    const [coords, setCoords] = useState({});
    const getLocalidadesPorProvincia = async() => {
        const provincia = await ProvinciaGet(credentials, "R")
        const response = await LocalidadGet(credentials, provincia.result[0].provinciaId)
        console.log(response, provincia)   
        setLocalidades(response)
    }
const setSnackBarInfo = useSetRecoilState(snackbarData);
const credentials = useRecoilValue(userCredentials);

const [inRequest, setInRequest] = useState(false);
const [ubicacion, setUbicacion] = useState(null)

const formikRef = useRef();

const handleConnection = async (values) => {
    console.log(values)
    console.log(ubicacion)
    const response = await MerchantSucursalUpd(
        credentials,
        {...values, registroXUsuarioLatitud : ubicacion?.lat?.toFixed(6), registroXUsuarioLongitud : ubicacion?.lng?.toFixed(6)},
    );
    console.log(response)
    if (response.length === 0) {
        formikRef.current.resetForm();
        updateList();
        closeModal();
        setSnackBarInfo({
            message: "Sucursal modificada correctamente",
            severity: "success",
            open: true,
        });
    }
}
const getInfo = async (cred) => {
    const data = await MerchantSucursalGet(cred, cred.merchantId, row.merchantSucursalId)
    console.log(data)
    formikRef.current.setValues({
        ...data?.result[0],
        merchantSucursalNombre: data.result[0].merchantSucursalNombre,
        domicilioCalle: data?.result[0]?.domicilioCalle,
        domicilioCasa: data?.result[0]?.domicilioCasa,
        domicilioPiso: data?.result[0]?.domicilioPiso,
        domicilioUnidad: data?.result[0]?.domicilioUnidad,
        domicilioCPA: data?.result[0]?.domicilioCPA,
        merchantSucursalTelefono: data?.result[0]?.merchantSucursalTelefono,
        localidadId: data?.result[0]?.localidadId,
    });
    setCoords(data.result[0].domicilioLatitud, data.result[0].domicilioLongitud)
}

useEffect(()=> {
    getLocalidadesPorProvincia()
    getInfo(credentials)
    navigator.geolocation.getCurrentPosition((data) => {
        setUbicacion({ lat: data.coords.latitude, lng: data.coords.longitude })
    }, (error) => console.log(error))
},[])
    return (
        <Formik
        innerRef={formikRef}
        initialValues={{
            merchantSucursalNombre: "",
            domicilioCalle: "",
            domicilioCasa: "",
            domicilioPiso: "",
            domicilioUnidad: "",
            domicilioCPA: "",
            merchantSucursalTelefono: "",
            localidadId: "",
        }}
        validationSchema={
            yup.object({
                merchantSucursalNombre: yup.string("Debe ingresar un nombre").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar un nombre"),
                domicilioCPA: yup.string().max(8, "Debe tener 8 caracteres").min(8, "Debe tener 8 caracteres").required("Debes ingresar tu domicilioCPAigo postal"),
                domicilioCalle: yup.string("Debe ingresar una calle").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar una calle"),
                domicilioCasa: yup.string("Debe ingresar un numero").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                merchantSucursalTelefono: yup.string("Debe ingresar un telefono").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                localidadId: yup.string().required("Debe seleccionar una localidad"),
            })
        }
        onSubmit={(values) => handleConnection(values)}
    >
        <Form className={styles.inputs} noValidate>
            <div className={styles["column-sm"]}>
                <>
                    <FormikTextInput
                        fullWidth={true}
                        name="merchantSucursalNombre"
                        labelText="Nombre"
                    />
                    <Box display={"flex"} width={"100%"} gap={4}>
                        <FormikTextInput
                            fullWidth={true}
                            name="domicilioCalle"
                            labelText="calle"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="domicilioCasa"
                            labelText="Número"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="domicilioPiso"
                            labelText="Piso"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="domicilioUnidad"
                            labelText="Unidad"
                        />
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4}>
                        <FormikSelectInput
                            fullWidth={true}
                            name="localidadId"
                            labelText="Localidad"
                            onChange={(e) => {
                                const selectLoc= localidades.find((localidad)=> localidad.localidadId === Number(e.target.value))
                                setCoords({
                                    lat: selectLoc.localidadGeoPINLatitud,
                                    lng: selectLoc.localidadGeoPINLongitud
                                })
                                formikRef.current.setFieldValue("localidadId", e.target.value);
                            }}
                        >
                            {localidades.map(({ localidadId, localidadNombre }) => (
                                <option
                                    key={localidadId}
                                    data-id={localidadId}
                                    value={localidadId}
                                >
                                    {localidadNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="domicilioCPA"
                            labelText="Código Postal"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="merchantSucursalTelefono"
                            labelText="Telefono"
                        />
                    </Box>
                </>
                <Map lat={coords?.lat} lng={coords?.lng} setCoords={setCoords} />


                <FormButton
                    inRequest={inRequest}
                    newFormData={{ button: "Modificar" }}
                    onCloseModal={closeModal}
                />

                {(inRequest) && <Spinner />}
            </div>
        </Form>
    </Formik>
  )
}
