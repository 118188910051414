import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { afipData, snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantEmpleadoIns } from '../../../../services/hooli-services/Merchants/MerchantEmpleadosIns.ts'
import { MerchantSucursalGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalGet.ts'
import { MerchantRolGet } from '../../../../services/hooli-services/Merchants/MerchantRolGet.ts'
import moment from 'moment';

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];

export default function AddEmpleados({updateList, servicio, closeModal}) {
    const recoilAfipData = useRecoilValue(afipData);
    const [sucursalesList, setSucursalesList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [disabledSucursal, setDisabledSucursal] = useState(false);

    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const formikRef = useRef();
    const getInfo = async(cred) => {
        const sucursales = await MerchantSucursalGet(cred, cred.merchantId)
        const roles = await MerchantRolGet(cred)
        setSucursalesList(sucursales.result)
        setRolesList(roles.result)
    }

    const handleConnection = async (values) => {
        const response = await MerchantEmpleadoIns(
            credentials,
            {merchantId: credentials.merchantId, ...values},
        );
        console.log(response)
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Beneficiario creada correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    useEffect(()=> {
        getInfo(credentials)
    },[])
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                usuarioNroDeDocumento: "",
                genero: "",
                merchantUsuarioRolId: "",
                merchantUsuarioSucursalId: "",
                merchantUsuarioFecha: moment(new Date()).format('YYYY-MM-DD'),
            }}
            validationSchema={
                yup.object({
                    usuarioNroDeDocumento: yup.string("Debe ingresar un documento").required("Debe ingresar un documento"),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="usuarioNroDeDocumento"
                            labelText="Documento"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="genero"
                            labelText="Genero"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("genero", e.target.value);
                            }}
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="merchantUsuarioRolId"
                            labelText="Rol"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("merchantUsuarioRolId", e.target.value);
                                if (e.target.value === 6 || e.target.value === "MD") {
                                    formikRef.current.setFieldValue("merchantUsuarioSucursalId", "");
                                    setDisabledSucursal(true);
                                } else {
                                    setDisabledSucursal(false);
                                }
                            }}
                        >
                            <option
                                    data-id={0}
                                    value={null}
                                >
                                    Todos
                                </option>
                            {rolesList.map(({ rolId, rolNombre }) => (
                                <option
                                    key={rolId}
                                    data-id={rolId}
                                    value={rolId}
                                >
                                    {rolNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="merchantUsuarioSucursalId"
                            labelText="Sucursal"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("merchantUsuarioSucursalId", e.target.value);
                            }}
                            disabled={disabledSucursal}
                        >
                            {formikRef?.current?.values?.merchantUsuarioRolId !== 9 && <option
                                    data-id={0}
                                    value={""}
                                >
                                    Todas
                                </option>}
                            {sucursalesList.map(({ merchantSucursalId, merchantSucursalNombre }) => (
                                <option
                                    key={merchantSucursalId}
                                    data-id={merchantSucursalId}
                                    value={merchantSucursalId}
                                >
                                    {merchantSucursalNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <Spinner />}
                </div>
            </Form>
        </Formik>
    );
}
