import React, { useState } from 'react';
import {Layaout} from "../../../components/layaout/index";
import { useFormik } from 'formik';
import {FormikTextInput} from "../../../components/utils/inputs/OldFormikInputs"
import { useStyles } from './styles';
import * as yup from "yup";
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import {AFIPDatosGet} from "../../../services/hooli-services/AFIP/AFIPDatosGet"
import {RemovePreviousMerchantTempFiles} from "../../../services/hooli-services/EliminarArchivosTemp/EliminarArchivosTemp"
import {MerchantCheck} from "../../../services/hooli-services/Merchants/MerchantCheck"
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { afipData, comercioData, loading, userCredentials, usuarioAdicionalData } from '../../../recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';

import { getLocalidades } from '../../../utils/getLocalidades';
import Loading from '../loading/Loading';
import { UsuarioAdicionalGet } from '../../../services/hooli-services/UsuarioAdicional/UsuarioAdicionalGet';
import ModalAltaComercio from '../../../components/alta-comercios/modal';
import { LocalidadGet } from '../../../services/hooli-services/Merchants/LocalidadGet';

function IngresoCUIT() {
    const inputCUITData = {cuit: {label: "Número", placeholder:"30-12345678-9"}}
    const schemValidation = yup.object({
        cuit: yup.string().required("Debes ingresar el número de CUIT").max(13,"Debes ingresar menos de 13 digitos incluidos guiones").min(13,"Debes ingresar más de 13 digitos incluidos guiones").matches(/^\d{2}-\d{8}-\d$/,"Debes ingresar un formato válido"),
    });
    const credentials = useRecoilValue(userCredentials);
    const setAfipData = useSetRecoilState(afipData);
    const [usuarioAdicional, setUsuarioAdicional] = useRecoilState(usuarioAdicionalData);
    const [load, setLoad] = useRecoilState(loading);
    const [comercio, setComercio] = useRecoilState(comercioData);
    const [titleError, setTitleError] = useState("");
    const [textError, setTextError] = useState("");
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const handleClickModal = ()=> { 
        setIsOpen(false);
        // navigate("/ingreso-cuit")
    }
    const handleSubmit = async (values)=> { 
        try { 
            setLoad(true);
            const check = await MerchantCheck(credentials, values.cuit);
            console.log(check)
            if(check.status.errcode === 30067){
                const response = await AFIPDatosGet(credentials, values.cuit); 
                let localidades = null; 
                if(response.status.code === 1 && response.result.length > 0){ 
                    sessionStorage.setItem("afipData", JSON.stringify(response.result[0]));
                    sessionStorage.setItem("cuit", values.cuit);
                    setAfipData({...response.result[0], cuit:values.cuit});
                    localidades = await LocalidadGet(credentials, response.result[0].domicilioFiscalProvinciaId);
                    console.log(localidades)
                    sessionStorage.setItem("localidades", JSON.stringify(localidades));
                    if(response.result[0].personaFJ === "F"){
                        const usuario = await UsuarioAdicionalGet(credentials); 
                        setUsuarioAdicional(usuario.result[0]);
                        if(usuario.result[0].usuarioCUIT !== values.cuit){
                            setLoad(false);
                            setTitleError("Los CUITs no coinciden");
                            setTextError("La CUIT del comercio debe ser la del Usuario");
                            setIsOpen(true);
                            return
                    }
                }
                    await RemovePreviousMerchantTempFiles(credentials, values.cuit)
                    setComercio({
                        merchantPFPJ: response.result[0].personaFJ,
                        merchantCUIT: values.cuit,
                        merchantRZ: response.result[0].personaFJ === "J" ? response.result[0].merchantRZ : `${response.result[0].usuarioApellidos}, ${response.result[0].usuarioNombres}`,
                        actividadAFIPCod: response.result[0].actividadAFIPCod,
                        condicionAFIPCod: response.result[0].condicionAFIPCod,
                    });
                    navigate("/necesitamos-datos");
                }
            } else if (check.status.errcode === 30095){
                setLoad(false);
                setTitleError("Este es un CUIT ya registrado");
                setTextError("Ya existe un comercio con este CUIT");
                setIsOpen(true);
            } else {
                setLoad(false);
                setTitleError("Ups...");
                setTextError("Algo salio mal");
                setIsOpen(true);
            }
            setLoad(false)
        }catch(e){ 
            console.log("error", e)
        }
    }

    const formik = useFormik({
        initialValues: {
            cuit: "",
        },
        validationSchema: schemValidation,
        onSubmit: handleSubmit,
       initialErrors:{
        cuit: true
       }
    });

  return (
    <>
    {load ? <Loading/> :<Layaout title={"Ingresá el número de CUIT que identifica a tu comercio"} subtitle={"Vamos a hacer una consulta a la ARCA con la CUIT de tu negocio."}>
        <div className={styles.containerInput}> 
             <FormikTextInput
                formik={formik}
                data={inputCUITData}
                name="cuit"
            /> 
        </div>
    <ButtonGradient action={formik.handleSubmit} text={"Continuar"} size={"medium"} disabled={!formik.isValid}/>
    </Layaout>}
    <ModalAltaComercio isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} oneButton title={titleError} text={textError} />
    </>
  )
}

export {
    IngresoCUIT
}
