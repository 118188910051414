import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { Identificacion } from '../../../components/alta-comercios/forms/identificacion';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData, loading, userCredentials } from '../../../recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';
import { Planes } from '../../../components/alta-comercios/forms/planes';
import ModalAltaComercio from '../../../components/alta-comercios/modal';
import { MerchantOnboardDo } from '../../../services/hooli-services/Merchants/MerchantOnboardDo';
import { RNPlanGet } from '../../../services/hooli-services/PlanGet/RNPlanGet';

export function ContainerPlanes({nextStep}) {
  const navigate = useNavigate()
  const credentials = useRecoilValue(userCredentials);
  const [comercio,setComercioData] = useRecoilState(comercioData)
  const [isOpen, setIsOpen] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [textError, setTextError] = useState("");
  const [planes, setPlanes] = useState(null);
  const [load, setLoad] = useRecoilState(loading);
  const planGet = async() => {
    const response = await RNPlanGet(credentials);
    // console.log(response)
    setPlanes(response.result)
  } 
  const handleClickModal = ()=> { 
    setIsOpen(false);
  }
  const handleOnboard = async(data) => {
    try{
      setLoad(true)
      const response = await MerchantOnboardDo(credentials, data)
      if(response.status.errcode === 10000){
        setLoad(false)
        setIsOpen(true)
    }
      if(response.status.code === 1){
        // console.log(response)
        setLoad(false)
        nextStep(6);
      } 
      if(response.status.code !== 1){
        // console.log(response, data)
        setLoad(false)
        setTitleError("Ups...");
        setTextError("Algo salio mal");
        setIsOpen(true);
      }
    } catch(e) {
      setLoad(false)
      console.error(e);
      setTitleError("Ups...");
      setTextError("Algo salio mal");
      setIsOpen(true);
    }
    sessionStorage.clear()
  }
  const validationSchema = yup.object({
    planCod: yup.string().nullable(true).required("Debes seleccionar un plan"),
    tyc: yup.bool().oneOf([true], "Debes aceptar los términos y condiciones"),
});
  const recoilAfipData = useRecoilValue(afipData);
  const formik = useFormik({
    initialValues: {
      planCod: null,
      // surCash: true,
      tyc: false
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
      // console.log(values);
      const newComercio = {...comercio, 
        planCod: values.planCod,
        // merchantCash: values.surCash === true ? "Y" : "N",
      }
      // console.log(newComercio)
      setComercioData(newComercio)
      handleOnboard(newComercio)
    },
    initialErrors:{
      planCod: true
    }
}); 
const back = () => {
  navigate("/ingreso-cuit")
}

useEffect(() => {
  planGet()
}, [])

  return (
    <> 
          <Planes formik={formik} planes={planes}/>
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={back}/>
        </Box>
        {/* <ModalAltaComercio isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title={titleError} text={textError}/> */}
    </>
  )
}
