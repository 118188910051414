import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantEmpleadoGet = async (arg: UserCredentialsType, merchantId: number, usuarioEmpleadoId: number) => {
    const body: AxiosPostBody = {
        "service": "MerchantEmpleadoGet",
        "params": {
            merchantId: merchantId,
            usuarioEmpleadoId
        }
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result
        
    } catch (err) {
        console.log(err)
    }
}