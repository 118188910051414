import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import Map from '../../../../components/map/index.jsx'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { afipData, snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantSucursalIns } from '../../../../services/hooli-services/Merchants/MerchantSucursalIns.ts'
import { LocalidadGet } from '../../../../services/hooli-services/Merchants/LocalidadGet.ts'
import { ProvinciaGet } from '../../../../services/hooli-services/Provincias/ProvinciasGet.ts'

export default function AddSucursal({ updateList, servicio, closeModal }) {
    const recoilAfipData = useRecoilValue(afipData);
    const [localidades, setLocalidades] = useState([{localidadId: 1, localidadNombre: "Ciudad Autónoma de Buenos Aires"}]);
    const [coords, setCoords] = useState({});
    const credentials = useRecoilValue(userCredentials);
    
    const getLocalidadesPorProvincia = async() => {
        const provincia = await ProvinciaGet(credentials, "R")
        const response = await LocalidadGet(credentials, provincia.result[0].provinciaId)
        console.log(response, provincia)   
        setLocalidades(response)
        formikRef.current.setFieldValue("localidadId", response[0].localidadId)
    }
  const getLocalidad = ()=> { 
    const findLocalidad = localidades.find((loc)=> loc.localidadId === Number(recoilAfipData.domicilioFiscalLocalidadId));
    if(findLocalidad) { 
      return [{localidadNombre: findLocalidad.localidadNombre, localidadId: findLocalidad.localidadId, lat: findLocalidad.localidadGeoPINLatitud, lng: findLocalidad.localidadGeoPINLongitud}]
    }else {
      return false
    }
  }
const setSnackBarInfo = useSetRecoilState(snackbarData);

const [inRequest, setInRequest] = useState(false);
const [ubicacion, setUbicacion] = useState(null)

const formikRef = useRef();

const handleConnection = async (values) => {
    const response = await MerchantSucursalIns(
        credentials,
        {...values, domicilioLatitud: coords.lat, domicilioLongitud: coords.lng, merchantId: credentials.merchantId, registroXUsuarioLatitud : ubicacion?.lat?.toFixed(6), registroXUsuarioLongitud : ubicacion?.lng?.toFixed(6),},
    );
    console.log(response)
    if (response.length === 0) {
        formikRef.current.resetForm();
        updateList();
        closeModal();
        setSnackBarInfo({
            message: "Sucursal creada correctamente",
            severity: "success",
            open: true,
        });
    }
}
useEffect(()=> {
    console.log("localidades")
    const loc = {
        lat: getLocalidad()[0]?.lat || localidades.find((localidad)=> localidad.localidadId === Number(formikRef?.values?.localidadId))?.localidadGeoPINLatitud,
        lng:getLocalidad()[0]?.lng || localidades.find((localidad)=> localidad.localidadId === Number(formikRef?.values?.localidadId))?.localidadGeoPINLongitud
    }
    setCoords({
        lat: loc.lat, 
        lng: loc.lng
    })
}, [formikRef?.values?.localidadId]);
useEffect(()=> {
    getLocalidadesPorProvincia()
    navigator.geolocation.getCurrentPosition((data) => {
        setUbicacion({ lat: data.coords.latitude, lng: data.coords.longitude })
    }, (error) => console.log(error))
},[])
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                merchantSucursalNombre: "",
                domicilioCalle: "",
                domicilioCasa: "",
                domicilioPiso: "",
                domicilioUnidad: "",
                domicilioCPA: "",
                merchantSucursalTelefono: "",
                localidadId: "",
            }}
            validationSchema={
                yup.object({
                    merchantSucursalNombre: yup.string("Debe ingresar un nombre").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar un nombre"),
                    domicilioCPA: yup.string().max(8, "Debe tener 8 caracteres").min(8, "Debe tener 8 caracteres").required("Debes ingresar tu domicilioCPAigo postal"),
                    domicilioCalle: yup.string("Debe ingresar una calle").max(70, "Debe tener menos de 70 caracteres").required("Debe ingresar una calle"),
                    domicilioCasa: yup.string("Debe ingresar un numero").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                    merchantSucursalTelefono: yup.string("Debe ingresar un telefono").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un numero"),
                    localidadId: yup.string().required("Debe seleccionar una localidad"),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="merchantSucursalNombre"
                            labelText="Nombre"
                        />
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikTextInput
                                fullWidth={true}
                                name="domicilioCalle"
                                labelText="calle"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="domicilioCasa"
                                labelText="Número"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="domicilioPiso"
                                labelText="Piso"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="domicilioUnidad"
                                labelText="Unidad"
                            />
                        </Box>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="localidadId"
                                labelText="Localidad"
                                onChange={(e) => {
                                    const selectLoc= localidades.find((localidad)=> localidad.localidadId === Number(e.target.value))
                                    console.log(selectLoc)
                                    setCoords({
                                        lat: selectLoc.localidadGeoPINLatitud,
                                        lng: selectLoc.localidadGeoPINLongitud
                                    })
                                    formikRef.current.setFieldValue("localidadId", e.target.value);
                                }}
                            >
                                {localidades.map(({ localidadId, localidadNombre }) => (
                                    <option
                                        key={localidadId}
                                        data-id={localidadId}
                                        value={localidadId}
                                    >
                                        {localidadNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikTextInput
                                fullWidth={true}
                                name="domicilioCPA"
                                labelText="Código Postal"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="merchantSucursalTelefono"
                                labelText="Telefono"
                            />
                        </Box>
                    </>
                    <Map lat={coords?.lat} lng={coords?.lng} setCoords={setCoords} />


                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <Spinner />}
                </div>
            </Form>
        </Formik>
    );
}
