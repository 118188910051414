import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikRadioGroup, FormikSelectInput, FormikTextInput, FormikYesOrNoInput } from '../../../utils/inputs/OldFormikInputs'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil';
import { afipData } from '../../../../recoilState/GlobalState';

function FormInformacionFiscal3({formik, paises, usuarioAdicional}) {
    const styles = useStyles();
    const recoilAfipData = useRecoilValue(afipData);
    const dataFormik = {
        sujetoObligado: {label: "Sujeto obligado*", info: "Indicar si se encuentra incluido dentro de la Nómina de Sujetos Obligados a informar a la Unidad de Información Financiera (UIF) establecidos en el artículo 20 de la Ley 25.246, modificatorias y complementarias."},
        politicamenteExpuesto: {label: "Politicamente expuesto*", info: "Personas humanas que cumplan funciones públicas de relevancia, incluyendo a las personas por cercanía, consanguinidad, o afinidad según la resolución UIF 35/2023 y modificatorias."},
        inversorNoResidenteFiscal: {label: "Inversor no residente fiscal*", info: "Comprende a aquellas Personas Humanas, Personas Jurídicas y Beneficiaros Finales con residencia fiscal en el exterior según lo establecido la RG 4056-E ARCA, modificatorias y/o complementarias"},
        paisResidencia: {label: "País de residencia"}, 
        tinNif: {label:"TIN/NIF", options: [{nombre:"TIN", value:"T"},{nombre:"NIF", value:"N"}]},
        numeroTinNif: {label: `Número de ${formik.values.tinNif === "T" || formik.values.tinNif === null ? "TIN" : "NIF"}`,  }
    }

    useEffect(() => {
      if(formik.values.inversorNoResidenteFiscal === "true") {
        formik.setFieldValue('paisResidencia', paises[0].paisId);
        formik.setFieldValue('tinNif', "T");
      }
    }, [formik.values.inversorNoResidenteFiscal])
    
  return (
    <Grid container width={"100%"} maxWidth={700} padding={"50px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box  display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}> 
                <FormikRadioGroup name={"sujetoObligado"} info={dataFormik.sujetoObligado.info} formik={formik} label={dataFormik.sujetoObligado.label} disabled={recoilAfipData.personaFJ === "F" && usuarioAdicional?.usuarioSujetoObligado !== null}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}> 
                <FormikRadioGroup name={"politicamenteExpuesto"} info={dataFormik.politicamenteExpuesto.info} formik={formik} label={dataFormik.politicamenteExpuesto.label} disabled={recoilAfipData.personaFJ === "F" && usuarioAdicional?.usuarioPoliticamenteExpuesto !== null}/>
            </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box  display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}> 
                <FormikRadioGroup name={"inversorNoResidenteFiscal"} info={dataFormik.inversorNoResidenteFiscal.info} right formik={formik} label={dataFormik.inversorNoResidenteFiscal.label} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikSelectInput name={"paisResidencia"} formik={formik} data={dataFormik} disabled={formik.values.inversorNoResidenteFiscal === "" ||formik.values.inversorNoResidenteFiscal === "false"} options={paises} optionName={"paisNombre"} optionValue={"paisId"} optionKey={"paisId"}/>
            </Box>
        </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box  display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikSelectInput name={"tinNif"} formik={formik} data={dataFormik} options={dataFormik.tinNif.options} label={dataFormik.tinNif.label} optionName={"nombre"} optionValue={"value"} optionKey={"value"}  disabled={formik.values.inversorNoResidenteFiscal === "" ||formik.values.inversorNoResidenteFiscal === "false"}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikTextInput name={"numeroTinNif"} formik={formik} data={dataFormik} disabled={formik.values.inversorNoResidenteFiscal === "" ||formik.values.inversorNoResidenteFiscal === "false"} />
            </Box>
        </Grid>

    </Grid>
  )
}

export { FormInformacionFiscal3 }
