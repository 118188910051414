import axiosPost from '../axiosPost';
import { Caja, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaIns = async (arg: UserCredentialsType, data: Caja) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaIns",
        "params": {
            merchantSucursalId: data.merchantSucursalId,
            merchantCajaNombre: data.merchantCajaNombre,
            merchantCajaNumero: data.merchantCajaNumero,
        }
    }
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        if(result?.status?.code === 0) {
            return [result.status]
        } else {
            return result.result;
        }
}