import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../utils/inputs/OldFormikInputs';
import { useStyles } from './styles';

import { useRecoilValue } from 'recoil';
import { afipData,provincias } from '../../../../recoilState/GlobalState';

function FormInformacionFiscal({formik}) {
    const styles = useStyles();
  
    const recoilAfipData = useRecoilValue(afipData);
    const recoilProvincias = useRecoilValue(provincias)
    const dataFormik = {
        regimenIngresosBrutos: {
            label:"Regimen de Ingresos Brutos*",
            placeholder:"",
            options: []
        },
        actividadPrincipal: { 
            label: "Actividad principal según la ARCA",
            placeholder:""
        },
        provinciaIngresosBrutos: { 
            label: "Provincia de Ingresos Brutos*",
            placeholder: "", 
            options: []
        },
        numeroIngresosBrutos: { 
            label:"Número de Ingresos Brutos*", 
            placeholder: ""
        }
    }
    console.log(recoilProvincias)

  return (
    <Grid container width={"100%"} padding={"50px 0"}>
    
        <Grid item width={"100%"} display={"flex"} gap={4}> 
             <Box width={"50%"} className={styles.containerActividadFiscal}> 
                <label>Actividad principal según la ARCA</label>
                 <textarea  name={"actividadPrincipal"} value={formik.values.actividadPrincipal} disabled={true} /> 
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"regimenIngresosBrutos"} formik={formik} disabled={true}/>
                <FormikSelectInput data={dataFormik} name={"provinciaIngresosBrutos"} formik={formik} options={recoilProvincias} optionValue={"provinciaId"} optionName={"provinciaNombre"} optionKey={"provinciaId"} disabled={recoilAfipData.IBCondicion === "M"} noSelect={true}/>
                <FormikTextInput data={dataFormik} name={"numeroIngresosBrutos"} formik={formik} disabled={recoilAfipData.IBCondicion === "M"} />
            </Box>
        </Grid>
      
    </Grid>
  )
}

export { FormInformacionFiscal }
