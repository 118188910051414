import React from 'react'
import styles from "../StylesGeneral.module.scss"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useEffect, useState } from "react";
import XGridServer from '../../../components/Grid/GridServer/GridServer';
import { userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState } from 'recoil';
import {MerchantSucursalGet} from "../../../services/hooli-services/Merchants/MerchantSucursalGet"
import HeaderButton from '../../../components/Button/HeaderButton';
import Modal from '../../../components/Modal/Modal';
import EditSucursal from './Actions/EditSucursal';
import AddSucursal from './Actions/AddSucursal';
import { ACTIONS } from '../../../consts/Actions';
import { Add, Cached, EditOutlined } from '@mui/icons-material';
import DelSucursal from './Actions/DelSucursal';

export default function Sucursales() {
    const [selectRow, setSelectRow] = useState({ status: false, row: {} })
    const [sucursalesList, setSucursalesList] = useState([])
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [page, setPage] = useState(1)
    const [rowsCount, setRowsCount] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const columns = [
        { field: 'sucursalNombre', headerName: 'Nombre', flex: 1, minWidth: 100 },
        { field: 'domicilioCalle', headerName: 'Calle', flex: 1, minWidth: 100 },
        { field: 'domicilioCasa', headerName: 'Casa', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
        { field: 'domicilioPiso', headerName: 'Piso', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
        { field: 'domicilioUnidad', headerName: 'Unidad', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
        { field: 'localidadNombre', headerName: 'Localidad', flex: 1, minWidth: 100 },
        { field: 'domicilioCPA', headerName: 'CPA', flex: 1, minWidth: 100, align: "right", headerAlign: 'right' },
    ];
    
    const getSucursales = async (cred) => {
        const data = await MerchantSucursalGet(cred, cred.merchantId)
        if(data.status.code === 1){
            let formatList = data.result.map((data, i) => ({
                id: data.merchantSucursalId,
                sucursalId: data.merchantSucursalId,
                sucursalNombre: data.merchantSucursalNombre,
                sucursalDireccion: `${data.domicilioCalle} ${data.domicilioCasa}`,
                domicilioCPA: data.domicilioCPA,
                domicilioCalle: data.domicilioCalle,
                domicilioCasa: data.domicilioCasa,
                domicilioLatitud: data.domicilioLatitud,
                domicilioLongitud: data.domicilioLongitud,
                domicilioPiso: data.domicilioPiso,
                domicilioUnidad: data.domicilioUnidad,
                localidadId: data.localidadId,
                localidadNombre: data.localidadNombre,
                merchantId: data.merchantId,
                merchantNombre: data.merchantNombre,
                merchantPlanDescuento: data.merchantPlanDescuento,
                merchantSucursalEsCasaCentral: data.merchantSucursalEsCasaCentral,
                merchantSucursalVersion: data.merchantSucursalVersion,
                merchantSucursalId: data.merchantSucursalId,
                merchantSucursalNombre: data.merchantSucursalNombre,
                merchantSucursalTelefono: data.merchantSucursalTelefono,
                provinciaNombre: data.provinciaNombre,
            }))
            setSucursalesList(formatList)
        }
    }
    
      const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    useEffect(() => {
        if (credentials.merchantId){
            getSucursales(credentials)
        }
    }, [credentials])
    useEffect(() => {
        if (openModal === false && credentials.merchantId){
            getSucursales(credentials)
        }
    }, [openModal])
  return (
    <>
        <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} /* close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} */ >
                {modalDetails.procede === ACTIONS.INSERT && (
                    <AddSucursal
                        user={selectRow.row}
                        // servicio={service}
                        updateList={() => getSucursales(credentials)}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <EditSucursal
                        user={selectRow.row}
                        // roles={connectionRoles}
                        updateList={() => getSucursales(credentials)}
                        closeModal={closeModal}
                        row={selectRow.row}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DelSucursal
                        user={selectRow.row}
                        // servicio={service}
                        updateList={() => getSucursales(credentials)}
                        closeModal={closeModal}
                        row={selectRow.row}
                    />
                )}
            </Modal>
        <div className={styles.container}>
            <div className={styles.containerFilters}>
                <div className={styles.containerButtons}>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getSucursales(credentials) }}
                        disabled={selectRow.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nueva sucursal"}
                        handleClick={handleClick}
                        data={{
                            title: "Nueva sucursal",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={selectRow.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar sucursal"}
                        handleClick={handleClick}
                        data={{
                            title: "Modificar sucursal",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!selectRow.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar sucursal"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar sucursal",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!selectRow.row.id}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                </div>
            </div>
            <XGridServer columns={columns} setNumberPage={setPage} rowsCount={rowsCount} setPageSize={setPageSize} list={sucursalesList} rowSelect={selectRow} setRow={setSelectRow} pageSize={pageSize} onPageSizeChange={setPageSize}/>
        </div>
    </>
    )
}
