import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantEmpleadoUpd } from '../../../../services/hooli-services/Merchants/MerchantEmpleadosUpd.ts'
import { MerchantEmpleadoGet } from '../../../../services/hooli-services/Merchants/MerchantEmpleadosGet.ts'
import { MerchantRolGet } from '../../../../services/hooli-services/Merchants/MerchantRolGet.ts'
import { MerchantSucursalGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalGet.ts'
import moment from 'moment';

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];

export default function EditEmpleados({user, updateList, servicio, closeModal}) {
    const [sucursalesList, setSucursalesList] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const formikRef = useRef();
    const getInfo = async(cred) => {
        const empleado = await MerchantEmpleadoGet(cred, cred.merchantId, user.usuarioEmpleadoId)
        formikRef.current.setValues({...empleado.result[0], merchantUsuarioFecha: moment().format('YYYY-MM-DD')})
        const sucursales = await MerchantSucursalGet(cred, cred.merchantId)
        const roles = await MerchantRolGet(cred)
        setSucursalesList(sucursales.result)
        setRolesList(roles.result)
    }

    const handleConnection = async (values) => {
        const response = await MerchantEmpleadoUpd(
            credentials,
            values,
        );
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Empleado modificado correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    useEffect(()=> {
        getInfo(credentials)
    },[])
    return (
        <Formik
        innerRef={formikRef}
        initialValues={{
            usuarioEmpleadoId: "",
            usuarioNroDeDocumento: "",
            genero: "",
            rolId: "",
            merchantSucursalId: "",
        }}
        validationSchema={
            yup.object({
                usuarioNroDeDocumento: yup.string("Debe ingresar un documento").required("Debe ingresar un documento"),
            })
        }
        onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="usuarioNroDeDocumento"
                            labelText="Documento"
                            disabled={true}
                            />
                        <FormikSelectInput
                            fullWidth={true}
                            name="genero"
                            labelText="Genero"
                            disabled={true}
                            onChange={(e) => {
                                formikRef.current.setFieldValue("genero", e.target.value);
                            }}
                            >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                key={sexoCod}
                                data-id={sexoCod}
                                value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rolId"
                            labelText="Rol"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("rolId", e.target.value);
                                console.log(formikRef?.current?.values)
                            }}
                        >
                            <option
                                    data-id={0}
                                    value={null}
                                >
                                    Todos
                                </option>
                            {rolesList.map(({ rolId, rolNombre }) => (
                                <option
                                    key={rolId}
                                    data-id={rolId}
                                    value={rolId}
                                >
                                    {rolNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="merchantSucursalId"
                            labelText="Sucursal"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("merchantSucursalId", e.target.value);
                            }}
                        >
                            <option
                                    data-id={0}
                                    value={null}
                                >
                                    Todas
                                </option>
                            {sucursalesList.map(({ merchantSucursalId, merchantSucursalNombre }) => (
                                <option
                                    key={merchantSucursalId}
                                    data-id={merchantSucursalId}
                                    value={merchantSucursalId}
                                >
                                    {merchantSucursalNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Modificar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <Spinner />}
                </div>
            </Form>
        </Formik>
    );
}