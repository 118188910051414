import React, { useEffect, useState } from "react";
import {  Navigate, Route, Routes as Switch, useNavigate } from "react-router-dom";
// Components
import { PagosHeader } from './components/pagos/index'
import { Header, SnackBar, ProtectedRoute } from './components/index'
// pages
import { Login, Dashboard, Saldos, Ventas, ACobrar, Movimientos, PagosPaso1, PagosHome, PagosPaso2, PagosCobros } from './pages';
// consts
import { Routes } from "./consts/Routes-App";
//Credentials services
import { usuarioMinimosGet, checkAuth,getUserCredentials } from './services/hooli-services';
import { } from "./services/hooli-services/checkAuth";
// recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userInfo, userCredentials, routeInfo, snackbarData } from './recoilState/GlobalState';
import AltaComercios from "./pages/altaComercios/AltaComercios.js";
import LandingPage from "./pages/landingPage/LandingPage.js";
import HeaderHooli from "./components/headerHooli/HeaderHooli.js";
import { IngresoCUIT } from "./pages/altaComercios/ingresoCUIT/index.jsx";
import { ContainerNoDisponibles } from "./pages/altaComercios/noDisponibles/index.jsx";
import { ListadoComercios } from "./pages/listado-comercios/index.jsx";
import { NecesitamosDatos } from "./pages/altaComercios/necesitamosDatos/index.jsx";
import { QueNecesitas } from "./pages/altaComercios/queNecesitas/index.jsx";
import { useLocation } from "react-router-dom";
import ModalAltaComercio from "./components/alta-comercios/modal/index.jsx";
import { subscribe, unsubscribe } from "./utils/eventManager.js";
import Cobros from "./pages/cobros/Cobros.js";
import Sucursales from "./pages/cobros/sucursales/Sucursales.js";
import Cajas from "./pages/cobros/cajas/Cajas.js";
import Empleados from "./pages/cobros/empleados/Empleados.js";

export default function App() {
    const setInfo = useSetRecoilState(userInfo);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [route, setRoute] = useRecoilState(routeInfo);
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [routeError, setRouteError] = useState(false);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const location = useLocation();
    const allowedRoutesHeaderHooli = [
        Routes.landing.route,
        Routes.necesitamosDatos.route,
        Routes.queNecesitas.route,
        Routes.altaComercios.route,
        "/ingreso-cuit",
        Routes.comercioNoDisponible.route,
        Routes.listadoComercios.route,
        "/"
      ];

      const handleClickModal = ()=> { 
        setIsOpen(false);
        if(routeError){
            navigate(routeError)
        }
    }

    useEffect(() => {
        if (route !== Routes[location.pathname.split('/')[1]]) {
            setRoute(Routes[location.pathname.split('/')[1]])
        }
    }, [window.location.pathname]);
    
    useEffect(() => {
        const handleErrorServer = (data) => {
            setSnackBarInfo({
                message: data.message,
                severity: "warning",
                open: true,
            });
        };
        const handleSessionExpired = (data) => {
            console.log("sessionExpired");
            setSnackBarInfo({
                message: data.message,
                severity: "warning",
                open: true,
            });
            setRouteError(Routes.login.route);
            navigate(Routes.login.route);
            setIsOpen(true);
        };
        subscribe("serverError", handleErrorServer);
        subscribe("sessionExpired", handleSessionExpired);
        if (location.pathname !== Routes.login.route) {
            // (async () => {
            //     const data = await getUserCredentials();
            //     setCredentials({ ...data, merchantId: 132, merchantRZ: 'Moov', merchantNombre: 'Moov', rolCod: 'MD' });
            // })();
            setCredentials(checkAuth());
        }
        return () => {
            unsubscribe("serverError");
            unsubscribe("sessionExpired");
        };
    }, []);

    useEffect(() => {
        async function retrieveUsuarioMinimo() {
            const data = await usuarioMinimosGet(credentials)
            if (data) setInfo(data)
        };
        if (Object.keys(credentials).length > 0) retrieveUsuarioMinimo()
    }, [credentials]);

    const checkAuth = () => {
        if(sessionStorage.getItem('hooliToken') && sessionStorage.getItem("sessionId")) {
            return true
        } else {
            return false
        }
    }

    //  useEffect(() => {
    //      if (Object.keys(credentials).length === 0) return;
    //      // Visita una ruta que no es login ni cobros y no es admin
    //      const noAutorizado = route.route !== Routes.login.route && credentials.rolCod !== 'MD' && credentials.rolCod !== 'MA' && !route.route.includes('/cobros');
    //      if (noAutorizado) return window.location.assign('/cobros');
    //  }, [route, credentials])
    return (
        <>  
        
        {(allowedRoutesHeaderHooli?.includes(location.pathname)) && <HeaderHooli />}
        {route?.route?.includes('/cobros') ? <PagosHeader /> : route?.route !== '/' && !allowedRoutesHeaderHooli?.includes(location.pathname) && route?.route !== Routes?.login?.route && <Header />} 
           
          
            <SnackBar />
            <Switch>
                {/* Login */}
                <Route path={Routes.login.route} exact element={<Login />} />

                {/* Prueba page */}
                <Route path={Routes.pos.route} exact element={<Cobros />} />
                <Route path={Routes.sucursales.route} exact element={<Sucursales />} />
                <Route path={Routes.cajas.route} exact element={<Cajas />} />
                <Route path={Routes.empleados.route} exact element={<Empleados />} />

                {/* Landing page */}
                <Route path={Routes.landing.route} exact element={<LandingPage />} />

                {/* Necesitamos datos */}
                <Route path={Routes.necesitamosDatos.route} exact element={
                <NecesitamosDatos /> 
                } 
                />

                {/* Que necesitas */}
                <Route path={Routes.queNecesitas.route} exact element={<QueNecesitas />} />

                {/* Alta comercios */}
                <Route path={Routes.altaComercios.route} exact element={
                    <AltaComercios /> 
                }
                    />
                {/* Ingreso CUIT */} 
                {
                    <Route path={Routes.ingresoCuit.route} exact element={
                        <IngresoCUIT /> }/>
                }
                {/* Comercio No Disponible */}
                <Route path={Routes.comercioNoDisponible.route} exact element={
                    checkAuth() ? <ContainerNoDisponibles /> : <Navigate to={Routes.login.route} />
                }/>
                <Route path={Routes.listadoComercios.route} exact element={
                    <ListadoComercios /> 
                }/>

                {/* Dashboard */}
                <Route path={Routes.dashboard.route} exact element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                {/* Tablas */}
                <Route path={Routes.saldos.route} exact element={
                    <ProtectedRoute>
                        <Saldos />
                    </ProtectedRoute>
                } />
                <Route path={Routes.ventas.route} exact element={
                    <ProtectedRoute>
                        <Ventas />
                    </ProtectedRoute>
                } />
                <Route path={Routes.aCobrar.route} exact element={
                    <ProtectedRoute>
                        <ACobrar />
                    </ProtectedRoute>
                } />
                <Route path={Routes.resumen.route} exact element={
                    <ProtectedRoute>
                        <Movimientos />
                    </ProtectedRoute>
                } />
                {/* Pagos */}
                <Route path={Routes.pagos.route} exact element={<PagosHome />} />
                <Route path={Routes.pagos.paso1.route} exact element={<PagosPaso1 />} />
                <Route path={Routes.pagos.paso2.route} exact element={ <PagosPaso2 />} />
                <Route path={Routes.pagos.cobros.route} exact element={ <PagosCobros />} />
            </Switch>
            {/* <ModalAltaComercio isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title={"Ups..."} text={errorMsg} oneButton={true} /> */}
            </>
       
    );
};