import axiosPost from '../axiosPost';
import { EmpleadoDel, MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantEmpleadoDel = async (arg: UserCredentialsType, data: EmpleadoDel) => {
    const body: AxiosPostBody = {
        "service": "MerchantEmpleadoDel",
        "params": {
            merchantUsuarioUsuarioId: data.usuarioEmpleadoId,
            merchantId: data.merchantId,
            merchantUsuarioFecha: data.merchantUsuarioFecha,
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        if(result?.status?.code === 0) {
            return [result.status]
        } else {
            return result.result;
        }
}