import React, { useEffect, useRef, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../../components/utils/inputs/FormikInputs.js'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { snackbarData, userCredentials } from '../../../../recoilState/GlobalState.js'
import { Form, Formik } from 'formik'
import * as yup from "yup";
import styles from "../../../../styles/pages/ModalActions.module.scss";
import FormButton from '../../../../components/Button/FormButton.jsx';
import Spinner from '../../../../components/utils/spinner/Spinner.js'
import { MerchantSucursalCajaIns } from '../../../../services/hooli-services/Merchants/MerchantSucursalCajaIns.ts'
import { MerchantSucursalGet } from '../../../../services/hooli-services/Merchants/MerchantSucursalGet.ts'
import { MerchantRolGet } from '../../../../services/hooli-services/Merchants/MerchantRolGet.ts'

export default function AddCajas({updateList, servicio, closeModal}) {
    const [sucursalesList, setSucursalesList] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const formikRef = useRef();

    const getInfo = async(cred) => {
        const sucursales = await MerchantSucursalGet(cred, cred.merchantId)
        const roles = await MerchantRolGet(cred)
        setSucursalesList(sucursales.result)
        setRolesList(roles.result)
        formikRef.current.setFieldValue("merchantSucursalId", sucursales.result[0].merchantSucursalId);
    }

    const handleConnection = async (values) => {
        const response = await MerchantSucursalCajaIns(
            credentials,
            values,
        );
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Caja creada correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    useEffect(()=> {
        getInfo(credentials)
    },[])
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                merchantCajaNombre: "",
                merchantCajaNumero: "",
                merchantSucursalId: "",
            }}
            validationSchema={
                yup.object({
                    merchantCajaNombre: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                    merchantCajaNumero: yup.string("Debe ingresar un número").required("Debe ingresar un número"),
                    merchantSucursalId: yup.string("Debe seleccionar una sucursal").required("Debe seleccionar una sucursal"),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="merchantCajaNombre"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="merchantCajaNumero"
                            labelText="Número"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="merchantSucursalId"
                            labelText="Sucursal"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("merchantSucursalId", e.target.value);
                            }}
                        >
                            {sucursalesList.map(({ merchantSucursalId, merchantSucursalNombre }) => (
                                <option
                                    key={merchantSucursalId}
                                    data-id={merchantSucursalId}
                                    value={merchantSucursalId}
                                >
                                    {merchantSucursalNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <Spinner />}
                </div>
            </Form>
        </Formik>
    );
}
