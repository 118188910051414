import { TextField,Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useEffect } from 'react'
import imgInfo from "../../../assets/icons/info.svg"

export function FormikTextInput({ data, name, info, formik, disabled, className, variant=false, error=false }) {
    let touched = false
    
    useEffect(() => {
        if (formik.validateOnBlur) {
            touched = true
        }
    }, [formik.touched])

    return (
        <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"column"} position={"relative"} width={className && "100%"}> 
        {info && <div className={`container-info`} style={{position: "absolute", top: "14px", right: "-20px"}}>
            <img src={imgInfo} alt="i" style={{display: "flex"}}/>
            <section className={`data`}><p>Si no sabes cual es tu CPA ingresá a <a href='https://www.correoargentino.com.ar/formularios/cpa' target="_blank">aquí</a>.</p></section>
        </div>}
        {error ? 
        <TextField
        label={data[name]?.label}
        name={name}
        placeholder={data[name]?.placeholder}
        margin="normal"
        type="text"
        disabled={disabled}
        value={formik.values[name]}
        onChange={e => {
            formik.handleChange(e);
        }}
        error={Boolean(formik.errors[name])}
        helperText={formik.errors[name]}
        InputLabelProps={{ shrink: true }}
        fullWidth={className ? false : true}
        className={className}
        variant={variant ? variant:"standard"}
    /> : 
    <TextField
        label={data[name]?.label}
        name={name}
        placeholder={data[name]?.placeholder}
        margin="normal"
        type="text"
        disabled={disabled}
        value={formik.values[name]}
        onChange={e => {
            formik.setFieldTouched(name);
            formik.handleChange(e);
        }}
        onBlur={e => {
            formik.setFieldTouched(name);
            formik.handleBlur(e);
        }}
        error={formik.touched[name] && formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        InputLabelProps={{ shrink: true }}
        fullWidth={className ? false : true}
        className={className}
        variant={variant ? variant:"standard"}
    />
    }
    {
        //  formik.touched[name] && formik?.errors[name] &&  <Typography variant='subtitle1' color={"error"} alignSelf={"start"} fontSize={"12px"} >{formik.errors[name]}</Typography>
    }
   
    </Box>
)
};
export function FormikNumberInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="number"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        />
    )
};
export function FormikSelectInput({ data, name, formik, disabled, options, optionKey, optionValue, optionName, className, noSelect = false }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        >
            {noSelect ? <option value="" disabled selected>Seleccionar</option> : null}
            {options.map(option => {
                return <option
                    key={option[optionKey]}
                    value={option[optionValue]}
                >
                    {option[optionName]}
                </option>
            })}
        </TextField>
    )
};
export function FormikYesOrNoInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        >
            <option value="Y">Si</option>
            <option value="N">No</option>
        </TextField>
    )
};
export function FormikDateInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="date"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        />
    )
};
export function FormikTimeInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="time"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
            variant='standard'
        />
    )
};
export function FormikRadioGroup({ name, formik, label, info, right, className,disabled }) {

    return (
        <>
        <FormControl disabled={disabled}>
            <div style={{display: "flex", alignItems:"center", gap: "10px"}}>
                <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
                {info && <div className={`container-info`}>
                    <img src={imgInfo} alt="i" style={{display: "flex"}}/>
                    <section className={`data ${right && "right"}`}>{info}</section>
                    </div>}
            </div>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={name}
                value={formik.values[name]}
                onChange={e => {
                    formik.setFieldTouched(name);
                    formik.handleChange(e);
                }}
                onBlur={e => {
                    formik.setFieldTouched(name);
                    formik.handleBlur(e);
                }}
            >
                <FormControlLabel control={<Radio />} label="Si" value={true} />
                <FormControlLabel control={<Radio />} label="No" value={false} />
            </RadioGroup>
        </FormControl>
        {
            // formik?.touched[name] && formik?.errors[name]  &&  <Typography variant='subtitle1' color={"error"} alignSelf={"start"} fontSize={"12px"} >{formik.errors[name]}</Typography>
       }
       </>
    )
};