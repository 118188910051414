import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";


const libraries = ["places"];


export default function Map(props) {
    const { lat, lng, style, setCoords } = props;
   
    const [latitud, setLatitud] = useState(null)
    const [longitud, setLongitud] = useState(null)
    const center = {
        lat: latitud ||  -41.132805,
        lng: longitud || -71.307885
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc",
        // libraries: {libraries}, 
      });

    const containerStyle = {
        width: "-webkit-fill-available",
        height: "220px",
        position: "relative",
        borderRadius: "10px",
        marginTop: "14px",
        ...style,
    };

    const handleChangeCoords = (lat, lng) => {
        // console.log("Coordinates changed:", { lat, lng });
        setCoords({ lat, lng });
    }

    useEffect(() => {
        setLatitud(parseFloat(lat))
        setLongitud(parseFloat(lng))
        return () => {
            setLatitud(null)
            setLongitud(null)
        }
    }, [lat, lng])

    return (
        <>
            {isLoaded && <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}  
            >
                <Marker position={center} draggable={true} onDragEnd={(e)=> handleChangeCoords(e.latLng.lat(), e.latLng.lng())} />
            </GoogleMap>}
        </>
    );
}
