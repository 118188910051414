import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantSucursalCajaDel = async (arg: UserCredentialsType, merchantCajaId: number) => {
    const body: AxiosPostBody = {
        "service": "MerchantSucursalCajaDel",
        "params": {
            merchantCajaId
        }
    }

    const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        if(result?.status?.code === 0) {
            return [result.status]
        } else {
            return result.result;
        }
}